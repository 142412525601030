import { createSlice } from '@reduxjs/toolkit';
import { getDefaultRange } from '../utils';

const layerFilters = sessionStorage.getItem('layerFilters')
  ? JSON.parse(sessionStorage.getItem('layerFilters'))
  : {
      fleet: {
        range: getDefaultRange('fleet'),
        assignmentNo: 50,
      },
      freight: {
        range: getDefaultRange('freight'),
        carriers: ['ENRU'],
      },
      external: {
        range: getDefaultRange('external'),
        interval: 'today',
        carriers: ['COYOTE', 'UBER'],
        boards: ['Coyote Logistics'],
      },
      supply: {
        range: getDefaultRange('supply'),
      },
    };

const initialState = Object.freeze({
  layerFilters,
});

const layerSlice = createSlice({
  name: 'layer',
  initialState,
  reducers: {
    setItem(state, action) {
      state.layerFilters = Object.assign(state.layerFilters, action.payload);
      sessionStorage.setItem('layerFilters', JSON.stringify(action.payload));
    },
  },
});

export const layerActions = layerSlice.actions;
export default layerSlice;

import { Button, DialogActions, DialogContent, DialogTitle, Dialog, CircularProgress } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/system';

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    margin: 'unset',
    padding: '20px 24px',
    borderRadius: 'unset',
  },
  '& .MuiDialogContent-root': {
    padding: 'unset',
    minWidth: '392px',
    minHeight: '97.5px',
  },
}));

const StyledButton = styled(Button)(() => ({
  padding: '0px 37px',
}));

export const CustomDialog = ({
  id,
  title,
  content,
  buttonCancelTitle = 'Cancel',
  buttonConfirmTitle = 'Continue',
  buttonCancelColor = 'primary',
  buttonConfirmColor = 'primary',
  hideExit = false,
  hideBackdrop = false,
  justifyActions = 'center',
  open,
  handleCancel,
  handleConfirm,
  loading = false,
  testid,
}) => {
  return (
    <>
      <StyledDialog
        className={`${id}`}
        hideBackdrop={hideBackdrop}
        sx={{ zIndex: '1500' }}
        onClose={handleCancel}
        open={open ?? false}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <DialogTitle variant="h5" fontWeight="600" padding="unset !important">
            {title}
          </DialogTitle>
          {!hideExit && (
            <IconButton color="primary" onClick={handleCancel}>
              <CloseIcon />
            </IconButton>
          )}
        </Stack>
        <DialogContent sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          {content}
        </DialogContent>
        <DialogActions sx={{ justifyContent: justifyActions }}>
          <StyledButton
            variant="outlined"
            color={buttonCancelColor}
            className="b-cancel"
            autoFocus
            onClick={handleCancel}
            data-testid={`${testid}_cancel`}
          >
            {buttonCancelTitle}
          </StyledButton>
          <StyledButton
            variant="contained"
            color={buttonConfirmColor}
            className="b-confirm"
            autoFocus
            onClick={handleConfirm}
            disabled={loading}
            data-testid={`${testid}_confirm`}
          >
            {loading ? <CircularProgress size={24} /> : buttonConfirmTitle}
          </StyledButton>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

CustomDialog.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  buttonCancelTitle: PropTypes.string,
  buttonConfirmTitle: PropTypes.string,
  buttonCancelColor: PropTypes.string,
  buttonConfirmColor: PropTypes.string,
  open: PropTypes.bool,
  hideBackdrop: PropTypes.bool,
  hideExit: PropTypes.bool,
  justifyActions: PropTypes.string,
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func,
  loading: PropTypes.bool,
  testid: PropTypes.string,
};

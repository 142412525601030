import { createSlice } from '@reduxjs/toolkit';
import { getLocalStorageData } from '../utils';

const key = 'notifications';
const notifications = getLocalStorageData(key, []);

const initialState = Object.freeze(notifications);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setItem(state, action) {
      state = Object.assign(state, action.payload);
      localStorage.setItem(key, JSON.stringify(action.payload));
    },
  },
});

export const notificationsActions = notificationsSlice.actions;
export default notificationsSlice;

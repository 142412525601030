import React, { createContext, useState, useEffect } from 'react';
import { env } from '../env';
import PropTypes from 'prop-types';
import { useApi } from '../hooks/useApi';

const SSEContext = createContext({
  status: EventSource.CONNECTING,
  eventSource: null,
});

const AppSSEProvider = ({ children }) => {
  const [status, setStatus] = useState(EventSource.CONNECTING);

  const [eventSource, setEventSource] = useState(null);
  const { feed } = useApi();

  const establishConnection = async () => {
    let _eventSource = eventSource || (await feed());

    _eventSource.addEventListener('open', () => {
      console.log('[SSE Opened]');
      setStatus(EventSource.OPEN);
      setEventSource(_eventSource);
    });

    _eventSource.addEventListener('error', closeConnection);

    _eventSource.addEventListener('close', closeConnection);

    return _eventSource;
  };

  const closeConnection = () => {
    console.log('[SSE Closed]');
    if (eventSource) eventSource.close();
    setStatus(EventSource.CLOSED);
    setEventSource(null);
  };

  useEffect(() => {
    if (!env.REACT_APP_TEST_MODE) {
      establishConnection();

      return () => closeConnection();
    }
  }, []);

  return <SSEContext.Provider value={{ status, eventSource }}>{children}</SSEContext.Provider>;
};

AppSSEProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { SSEContext, AppSSEProvider };

import { createSlice } from '@reduxjs/toolkit';

const initialState = Object.freeze({
  markers: {},
  assignments: {},
  locations: {},
  drivers: {},
  business: {},
});

const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setData(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const serviceActions = serviceSlice.actions;
export default serviceSlice;

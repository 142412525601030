import { useState } from 'react';
import { getLocalStorageData } from '../utils';

function useAuth() {
  const [user, setUser] = useState(getLocalStorageData('user', null));

  const updateUser = userData => {
    setUser(userData);
  };

  const getUser = () => user;

  return { getUser, updateUser };
}

export { useAuth };

import { env } from './env';

const storedAppVersion = localStorage.getItem('appVersion');
const actualAppVersion = env.REACT_APP_VERSION;

if (!storedAppVersion || storedAppVersion !== actualAppVersion) {
  const keysToRemove = [
    'userConfig',
    'fleetFilters',
    'fleetFiltersPreset',
    'freightFilters',
    'freightFiltersPreset',
    'externalFreightFilters',
    'externalFreightFiltersPreset',
  ];
  keysToRemove.forEach(key => localStorage.removeItem(key));

  //session storage invalidation
  const sessionKeysToRemove = ['layerFilters'];
  sessionKeysToRemove.forEach(key => sessionStorage.removeItem(key));

  localStorage.setItem('appVersion', actualAppVersion);
}

/* eslint-disable no-undef */
import moment from 'moment-timezone';
import 'moment-duration-format';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

// moment.tz.setDefault('America/Chicago');

export function getEnv() {
  const hostname = window.location.hostname ?? '';
  if (hostname.startsWith('dev.') || hostname.startsWith('localhost')) return 'development';
  if (hostname.startsWith('test.')) return 'testing';
  if (hostname.startsWith('stg.')) return 'staging';
  return 'production';
}

export function getApiUrl() {
  return `https://api.${window.location.hostname}/api/`;
}

export const parseDateWithoutTZ = datetime => moment(datetime);

export const parseDateWithTZ = (datetime, keepLocalTime = false, timezone = 'America/Chicago') =>
  parseDateWithoutTZ(datetime).tz(timezone, keepLocalTime);

export const isValidDateTime = datetime => parseDateWithoutTZ(datetime).isValid();

export const getCurrentTimeWithoutTZ = () => moment();

export const getCurrentTime = (timezone = 'America/Chicago') => getCurrentTimeWithoutTZ().tz(timezone);

export function calculateTime(type, time) {
  const seconds = type * 60 * 60 - time;
  return seconds;
}

export function getLocationData(location) {
  if (!location) return '------';

  const city = convertToTitleCase(location.city || location.data?.attributes.city) || '----';
  const state = (location.state || location.data?.attributes.state)?.toUpperCase() || '----';

  return `${city}, ${state}`;
}

export function getLocationCity(location) {
  if (!location) return '------';
  return `${location.city}`;
}

export function getLocationCoordinates(location) {
  if (!location) return '------';

  const lat = location.lat || location.data.attributes.lat;
  const lon = location.lon || location.lng || location.data.attributes.lon || location.data.attributes.lng;

  return `${lat}, ${lon}`;
}

export const markerTypeMapping = {
  O: 'Origin',
  D: 'Destination',
  S: 'Stop',
  P: 'PartialPickup',
  T: 'T-Node',
};

export const shipmentEventTypeMaping = {
  P: 'Pending',
  C: 'Completed',
  X: 'Cancelled',
  U: 'Unassigned',
  A: 'Assigned',
  UNKNOWN: 'Unknown',
  D: 'Dispatched',
};

export const shipmentEventTypeFullMaping = Object.fromEntries(
  Object.entries(shipmentEventTypeMaping).map(([key, value]) => [key, `${key === 'UNKNOWN' ? 'UN' : key} - ${value}`])
);

export const markerColorMapping = {
  O: '#74B816',
  D: '#F76707',
  S: '#74B816',
  P: '#74B816',
  T: '#74B816',
};

export function getDuration(seconds) {
  const modifier = seconds < 0 ? '--' : '';
  const duration = moment.duration(Math.abs(seconds), 'seconds');
  if (!duration.isValid()) return '--';
  const hours = Math.floor(duration.asHours());
  const _hours = hours ? `${hours}[h] ` : '';
  const formattedTime = moment.utc(duration.asMilliseconds()).format(`${_hours}m[min]`);
  return `${modifier}${formattedTime}`;
}

export function getDiffIn(startDate, endDate, as = 'days') {
  const start = parseDateWithoutTZ(startDate).set({ seconds: 1 });
  const end = parseDateWithoutTZ(endDate).set({ seconds: 1 });

  const diff = moment.duration(end.diff(start));

  if (as === 'hours') return Math.floor(diff.asHours());
  return Math.floor(diff.asDays());
}

export function mapEventTypeName(eventType) {
  switch (eventType) {
    case 'O':
      return 'Origin';
    case 'S':
      return 'Stop';
    case 'P':
      return 'PartialPickup';
    case 'D':
      return 'Destination';
    case 'HTS':
      return 'HomeTimeStart';
    case 'HTE':
      return 'HomeTimeEnd';
  }

  return 'None';
}

export function getDefaultRange(mode) {
  const rangeMap = {
    fleet: [getCurrentTimeWithoutTZ().toISOString(), getCurrentTimeWithoutTZ().add(4, 'days').toISOString()],
    freight: [getCurrentTimeWithoutTZ().toISOString(), getCurrentTimeWithoutTZ().endOf('day').toISOString()],
    //freight: [getCurrentTimeWithoutTZ().subtract(2, 'days').toISOString(), getCurrentTimeWithoutTZ().add(3, 'days').endOf('day').toISOString()],
    external: [getCurrentTimeWithoutTZ().toISOString(), getCurrentTimeWithoutTZ().toISOString()],
    supply: [
      getCurrentTimeWithoutTZ().subtract(2, 'days').toISOString(),
      getCurrentTimeWithoutTZ().add(4, 'days').toISOString(),
    ],
  };

  return rangeMap[mode];
}

export function getDateRange(daysToAddToStart, daysToAddToEnd) {
  const currentDate = new Date();
  const startDate = new Date(currentDate);
  startDate.setDate(startDate.getDate() + daysToAddToStart);
  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + daysToAddToEnd);
  return { start: startDate, end: endDate };
}

export function getLocalIsoString(date) {
  return parseDateWithoutTZ(date).utc(true).toISOString();
}

export function getCurrentTimestamp() {
  return getCurrentTimeWithoutTZ().utc().valueOf();
}

export function convertToUTC(dateTimeString, sourceTimeZone = 'America/Chicago') {
  return moment.tz(dateTimeString, sourceTimeZone).utc();
}

export function isEpochDate(datetime) {
  return (
    datetime == '1969-12-31 18:00:00' ||
    datetime == '1970-01-01 00:00:00' ||
    datetime == '1970-01-01T00:00:00' ||
    datetime == '1970-01-01T00:00:00-06:00' ||
    datetime == '1970-01-01T00:00:00-05:00'
  );
}

export function tempGetTime(datetime) {
  if (!datetime || isEpochDate(datetime)) return '--';
  const date = parseDateWithoutTZ(datetime);
  if (!date.isValid()) return '--';
  const _formatedDatetime = `${date.format('MM-DD-YYYY')} at ${date.format('HH:mm')}`;
  return _formatedDatetime;
}

export function getDateTimeV2(datetime, format = 'YYYY-MM-DD HH:mm:ss z') {
  if (!datetime || isEpochDate(datetime)) return null;
  const date = parseDateWithTZ(datetime);
  return date.format(format);
}

export const getTimeWithoutDateV2 = datetime => getDateTimeV2(datetime, 'HH:mm');

export const getDateWithoutTimeV2 = datetime => getDateTimeV2(datetime, 'MM-DD-YYYY');

export const getTimezoneV2 = datetime => getDateTimeV2(datetime, 'z');

export function getDatetimeISOStringOrNull(datetime) {
  if (datetime && !isEpochDate(datetime)) {
    return parseDateWithTZ(datetime).toISOString();
  }
  return null;
}

export function getDatetimeObjectOrNull(datetime) {
  if (datetime && !isEpochDate(datetime)) {
    return parseDateWithTZ(datetime);
  }
  return null;
}

export function checkIfDatetimeIsAfter(baseDatetime, afterDatetime) {
  if (!isEpochDate(baseDatetime) && !isEpochDate(afterDatetime)) {
    return parseDateWithTZ(afterDatetime).isAfter(parseDateWithoutTZ(baseDatetime));
  }
  return null;
}

export function formatDateObjectToFlatpickr(datetime, offset) {
  return parseDateWithoutTZ(parseDateWithoutTZ(datetime).utcOffset(offset).format('YYYY-MM-DDTHH:mm:ss')).toISOString();
}

export function formatDateObjectFromFlatpickr(datetime) {
  let offset = parseDateWithTZ(datetime).format('Z');
  return `${parseDateWithoutTZ(datetime).format('YYYY-MM-DDTHH:mm:ss')}${offset}`;
}

export function getTimezoneOffset(date) {
  return date.utcOffset();
}

export function getTime(datetime, emptyValue = '--') {
  if (!datetime || isEpochDate(datetime)) return emptyValue;
  const date = parseDateWithoutTZ(datetime);
  if (!date.isValid()) return emptyValue;
  const _formatedDatetime = date.format('MM-DD-YYYY, HH:mm') + ` CST`;
  return _formatedDatetime;
}

export function getDateWithoutTime(datetime, format = 'MM-DD-YYYY', allowEpoch = false) {
  if (!datetime || (!allowEpoch && isEpochDate(datetime))) return '--';
  const date = parseDateWithoutTZ(datetime);
  if (!date.isValid()) return '--';
  const _formatedDatetime = date.format(format);
  return _formatedDatetime;
}

export function getTimeWithoutDate(datetime, format = 'HH:mm', allowEpoch = false) {
  if (!datetime || (!allowEpoch && isEpochDate(datetime))) return '--';
  const date = parseDateWithoutTZ(datetime);
  if (!date.isValid()) return '--';
  const _formatedDatetime = date.format(format) + ` CST`;
  return _formatedDatetime;
}

export function getDateTimeWithTimezone(datetime, allowEpoch = false) {
  if (!datetime || (!allowEpoch && isEpochDate(datetime))) return '--';
  const date = parseDateWithoutTZ(datetime);
  if (!date.isValid()) return null;
  return date.format('HH:mm  dddd M/DD') + ` CST`;
}

// For format: "2023-09-30 02:00:00"
const parseDateTime = dateTimeString => {
  if (!dateTimeString) return '--';
  const [datePart, timePart] = dateTimeString.split(' ');
  const [year, month, day] = datePart.split('-').map(Number);
  const [hours, minutes, seconds] = timePart.split(':').map(Number);

  return new Date(year, month - 1, day, hours, minutes, seconds);
};

export function getDate(datetime) {
  const date = parseDateWithoutTZ(datetime);
  if (!date.isValid()) return '____-__-__';
  const _formatedDatetime = date.format('YYYY-MM-DD');
  return _formatedDatetime;
}

export function getDateTime(datetime) {
  const date = convertToUTC(datetime);
  if (!date.isValid()) return '____-__-__';
  const _formatedDatetime = date.format('YYYY-MM-DD HH:mm');
  return _formatedDatetime;
}

// To format: "2024-02-01T05:00:20"
export const formatDateToCustomString = date => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const timeString = [date.getHours(), date.getMinutes(), date.getSeconds()]
    .map(num => String(num).padStart(2, '0'))
    .join(':');

  return `${year}-${month}-${day}T${timeString}`;
};

export function formatCurrency(value) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
}

export function measurePerformance(callback, method) {
  // start the timer
  const startTime = performance.now();

  // call the method you want to measure
  callback();

  // end the timer
  const endTime = performance.now();

  // calculate the elapsed time in milliseconds
  const elapsedTime = endTime - startTime;

  console.log(`${method} Elapsed time: ${elapsedTime} ms`);
}

// Function to generate random latitude within a specific range
function getRandomLatitude() {
  var minLat = -90;
  var maxLat = 90;
  return Math.random() * (maxLat - minLat) + minLat;
}

// Function to generate random longitude within a specific range
function getRandomLongitude() {
  var minLng = -180;
  var maxLng = 180;
  return Math.random() * (maxLng - minLng) + minLng;
}

export function generateFakeMarkers() {
  let markers = [];
  for (var i = 0; i < 1000; i++) {
    var lat = getRandomLatitude();
    var lng = getRandomLongitude();

    var _marker = { type: 'Truck', visible: 1, lat, lng, id: i, name: 'test+' + i };
    markers.push(_marker);
  }

  return markers;
}

export const getNestedAttribute = (obj, path) => {
  const keys = path.split('.');
  return keys.reduce((acc, key) => (acc ? acc[key] : undefined), obj);
};

const sortingComparator = (a, b, orderBy, orderType) => {
  let valueA = a[orderBy] ?? getNestedAttribute(a, orderBy);
  let valueB = b[orderBy] ?? getNestedAttribute(b, orderBy);

  if (orderType === 'date') {
    valueA = valueA ? parseDateTime(valueA) : null;
    valueB = valueB ? parseDateTime(valueB) : null;
  } else if (orderType === 'number') {
    valueA = parseFloat(valueA);
    valueB = parseFloat(valueB);
  }

  if (valueB < valueA) return -1;
  if (valueB > valueA) return 1;
  return 0;
};

export const getComparator = (order, orderBy, orderType) => {
  return order === 'desc'
    ? (a, b) => sortingComparator(a, b, orderBy, orderType)
    : (a, b) => -sortingComparator(a, b, orderBy, orderType);
};

export const formatElapsedTime = hours => {
  const days = Math.floor(hours / 24);
  const remainingHours = Math.floor(hours % 24);
  const minutes = Math.round((hours % 1) * 60); // calculate minutes

  return `${days}d, ${remainingHours}h, ${minutes}min (${hours})`;
};

export const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const formatDuration = hours => {
  // Convert hours to milliseconds for moment.duration
  const milliseconds = hours * 60 * 60 * 1000;
  const duration = moment.duration(milliseconds);

  // Format the duration
  return duration.format('d[d] h[h] m[m]', {
    trim: 'both',
  });
};

export const getElapsedTimeFromUTC = (elapsed, daysFormat = false) => {
  var days;
  var hours;
  var minutes;
  var decimalHours;

  var n = new Date(0, 0);
  var sign = Math.sign(elapsed);

  decimalHours = Math.abs(elapsed);
  n.setMinutes(Math.round(decimalHours * 60));

  days = n.getDate() - 1;
  hours = n.getHours();
  minutes = n.getMinutes();

  if (daysFormat) {
    hours = 24 * days + hours;
  }

  var result = sign === -1 ? '-' : '';

  if (days > 0) {
    result += days + 'd, ';
  }

  if (hours > 0 || days > 0) {
    result += hours + 'h, ';
  }

  if (minutes > 0 || hours > 0 || days > 0) {
    result += minutes + 'min';
  }

  return result;
};

export const getElapsedTime = (end, timezone = 'America/Chicago') => {
  var now = getCurrentTime();
  var future = moment.tz(end, timezone);

  var duration = moment.duration(future.diff(now));

  var days = Math.floor(duration.asDays());
  var hours = duration.hours();
  var minutes = duration.minutes();

  var result = '';

  if (days > 0) {
    result += days + 'd, ';
  }

  if (hours > 0) {
    result += hours + 'h, ';
  }

  if (minutes > 0) {
    result += minutes + 'min';
  }

  return result;
};

export const getAcronym = str => {
  const words = str.split(' ');
  return words.length === 1 ? words[0].substring(0, 2) : words.map(word => word[0]).join('');
};

export const convertToTitleCase = str => {
  if (!str || str.trim() === '') return str;

  return str
    .split(' ')
    .map(word =>
      word.charAt(0) !== '(' && word.charAt(-1) !== ')'
        ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        : word.toUpperCase()
    )
    .join(' ')
    .trim();
};

function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

function toDegrees(radians) {
  return radians * (180 / Math.PI);
}

export const calculateDegree = (coord1, coord2) => {
  let lat1 = toRadians(coord1.lat);
  let lat2 = toRadians(coord2.lat);
  let deltaLong = toRadians(coord2.lng - coord1.lng);

  let x = Math.cos(lat2) * Math.sin(deltaLong);
  let y = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(deltaLong);

  let initialBearing = Math.atan2(x, y);

  // Convert result from radians to degrees
  let bearing = toDegrees(initialBearing);

  // Normalize the result to lie between 0 and 360°
  return (bearing + 360) % 360;
};

export const getCardinalDirection = markers => {
  const directions = {};
  markers.forEach(m => {
    if (!directions[m.direction]) directions[m.direction] = [];
    directions[m.direction].push(m);
  });
  return directions;
};

export const arraysAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((value, index) => value === arr2[index]);
};

export const objectsAreEqual = (obj1, obj2) => {
  // If same reference or simple values
  if (obj1 === obj2) return true;

  // If either is null or not an object
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // If they don't have the same number of keys
  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!(key in obj2)) return false; // Key not in obj2
    if (!objectsAreEqual(obj1[key], obj2[key])) return false; // Values are different
  }

  return true;
};

export const isNotEmpty = data => !isEmpty(data);

export const haversineDistance = (origin, destination) => {
  const toRadians = angle => angle * (Math.PI / 180);

  const R = 6371; // Earth radius in kilometers
  //const R = 3959; // Earth radius in miles

  const dLat = toRadians(destination.lat - origin.lat);
  const dLon = toRadians(destination.lon - origin.lon);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(origin.lat)) * Math.cos(toRadians(destination.lat)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
};

export const getMapKey = (keepBounds = false) => {
  const sign = keepBounds ? -1 : 1;
  return sign * getCurrentTimeWithoutTZ().valueOf();
};

export const getPredefinedDriverStatus = () => {
  return [
    {
      name: 'Driving',
    },
    {
      name: 'OffDuty',
    },
    {
      name: 'OnDuty',
    },
    {
      name: 'Scheduled',
    },
    {
      name: 'Sleeper',
    },
  ];
};

export const getPredefinedCarriers = () => {
  return [
    {
      name: 'Enru',
      color: '#1667AD',
      // image: {
      //   src: '/assets/logos/enru.png',
      //   alt: 'Enru - Logo',
      // },
    },
    {
      name: 'Salem',
      color: '#F59F00',
    },
  ];
};

export const getPredefinedFleetTypes = () => {
  return [
    {
      name: 'Local',
    },
    // {
    //   name: 'OTR',
    // },
    {
      name: 'Weekly',
    },
  ];
};

// [{D: 12, O: 2}, {S: 2, D: 2}] => {D: 14, O: 2, S: 2}
export const mergeAndSumTypes = (acc, obj) => {
  return Object.entries(obj).reduce((result, [key, value]) => {
    result[key] = (result[key] || 0) + value;
    return result;
  }, acc);
};

export const getColorPalette = [
  '#ffffcc',
  '#ffcc99',
  '#933838',
  '#ff99cc',
  '#ef7564',
  '#ffccff',
  '#cc99ff',
  '#00897b',
  '#ccccff',
  '#99ccff',
  '#ffb74d',
  '#ccffff',
  '#99ffcc',
  '#5c7cab',
  '#ccffcc',
  '#ccff99',
  '#2c5494',
  '#8dc05a',
  '#75787b',
  '#8d6e63',
];

export const getLocalStorageData = (key, defaultData) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : defaultData;
};

export const getSessionStorageData = (key, defaultData) => {
  const data = sessionStorage.getItem(key);
  return data ? JSON.parse(data) : defaultData;
};

export const parseTruckIds = (truckIds, newId) => {
  if (truckIds.includes(newId)) {
    return truckIds.split(',');
  }
  const parsedIds = [...truckIds.split(','), newId];
  if (parsedIds.length > 3) {
    parsedIds.shift();
  }
  return parsedIds;
};

export const areURLsEqual = (oldUrl, newUrl) => {
  oldUrl.forEach((value, key) => {
    if (newUrl.get(key) !== value) return true;
  });

  return false;
};

export const addNotificationToStorage = newValue => {
  const notificationsInStorage = JSON.parse(localStorage.getItem('notifications')) || [];

  const existingNotificationIndex = notificationsInStorage.findIndex(notification => notification.id === newValue.id);

  if (existingNotificationIndex === -1) {
    const updatedArray = [...notificationsInStorage, newValue];
    localStorage.setItem('notifications', JSON.stringify(updatedArray));
  } else {
    const updatedArray = [...notificationsInStorage];
    updatedArray[existingNotificationIndex] = { ...updatedArray[existingNotificationIndex], ...newValue };
    localStorage.setItem('notifications', JSON.stringify(updatedArray));
  }
};

export const handleError = (error, message) => {
  toast.error(`${error.message}: ${message}`);
};

export const errorMessageMapping = {
  E01: { message: 'Truck unseated', severity: 'error' },
  E02: { message: 'No "T" Event', severity: 'warning' }, // TODO: needed update
  E03: { message: '"T" not connected', severity: 'warning' }, // TODO: needed update
  E04: { message: 'Pickup Event In the Past', severity: 'warning' },
  E05: { message: 'Dispatched event cannot be completed on time', severity: 'warning' }, // TODO: needed update
};

export const consideredButRejectedMapping = {
  E10: { message: 'Unable to service Origin on time', severity: 'error', location: 'Origin' },
  E11: { message: 'Completing Origin event violates HOS', severity: 'warning', location: 'Origin' },
  E12: { message: 'Unable to service Pickup on time', severity: 'error', location: 'PartialPickup' },
  E13: { message: 'Completing Pickup event violates HOS', severity: 'warning', location: 'PartialPickup' },
  E14: { message: 'Unable to service Stop on time', severity: 'error', location: 'Stop' },
  E15: { message: 'Completing Stop event violates HOS', severity: 'warning', location: 'Stop' },
  E16: { message: 'Unable to service Destination on time', severity: 'error', location: 'Destination' },
  E17: { message: 'Completing Destination violates HOS', severity: 'warning', location: 'Destination' },
  E18: { message: 'Load > distance pruning value', severity: 'info', location: null }, // TODO: needed update
  E19: { message: 'Load > time pruning value', severity: 'info', location: null }, // TODO: needed update
  E20: { message: 'Load pickup has > pruning value for dwell', severity: 'info', location: null }, // TODO: needed update
  E21: { message: 'Cannot make it to hometime on time', severity: 'info', location: null },
};

// use this to download data from swagger endpoint as a file for api generation script
// visit the /docs route on dev and save the openapoi response as the one name 'get-docs-new.json'
export function downloadJson(content, fileName = 'get-docs-new.json', contentType = 'text/plain') {
  var a = document.createElement('a');
  var file = new Blob([JSON.stringify(content)], { type: contentType });
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
}

export function getSortedItemsByPTA(items, order = 'asc') {
  const sortedWithPTA = items.reduce(
    (acc, item) => {
      let _pta = null;

      if (item.driver) {
        const manualPTA = item.driver.manual_pta || item.driver?.data?.attributes?.manual_pta;
        const systemPTA = item.driver.system_pta || item.driver?.data?.attributes?.system_pta;

        const hasManualPTA =
          !isEpochDate(manualPTA) && checkIfDatetimeIsAfter(getCurrentTime(), parseDateWithTZ(item.driver?.manual_pta));

        _pta = hasManualPTA
          ? parseDateWithoutTZ(manualPTA).unix()
          : !isEpochDate(systemPTA)
            ? parseDateWithoutTZ(systemPTA).unix()
            : null;
      }

      _pta ? acc.withPTA.push({ ...item, driver: { ...item.driver, _pta: _pta } }) : acc.withoutPTA.push(item);

      return acc;
    },
    { withPTA: [], withoutPTA: [] }
  );

  return [...sortedWithPTA.withPTA.sort(getComparator(order, 'driver._pta', 'number')), ...sortedWithPTA.withoutPTA];
}

export const generateMockTimelineData = (count = 300) => {
  const startTime = getCurrentTimeWithoutTZ();
  const endTime = getCurrentTimeWithoutTZ().add(8, 'hours');

  const items = [];
  for (let i = 0; i < count; i++) {
    items.push(
      {
        id: i * 4 + 1,
        group: i + 1,
        title: 'Load',
        start_time: startTime,
        end_time: parseDateWithoutTZ(startTime).add(2, 'hours'),
        type: 'load',
      },
      {
        id: i * 4 + 2,
        group: i + 1,
        title: 'Dwell',
        start_time: parseDateWithoutTZ(startTime).add(2, 'hours'),
        end_time: parseDateWithoutTZ(startTime).add(4, 'hours'),
        type: 'dwell',
      },
      {
        id: i * 4 + 3,
        group: i + 1,
        title: 'Deadhead',
        start_time: parseDateWithoutTZ(startTime).add(4, 'hours'),
        end_time: parseDateWithoutTZ(startTime).add(5, 'hours'),
        type: 'deadhead',
      },
      {
        id: i * 4 + 4,
        group: i + 1,
        title: 'Hometime',
        start_time: parseDateWithoutTZ(startTime).add(5, 'hours'),
        end_time: endTime,
        type: 'hometime',
      }
    );
  }

  return items;
};

export const generateGroups = (count = 300) => {
  const groups = [];
  for (let i = 0; i < count; i++) {
    groups.push({ id: i + 1, title: `Driver ${i + 1}` });
  }
  return groups;
};

export const getDrivenMiles = shipment => {
  return shipment?.stops?.find(s => ['O', 'HTS'].includes(s.event_type))?.details?.driven_miles;
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = Object.freeze({
  markers: JSON.stringify({}),
  selectedMarker: JSON.stringify({}),
});

const markersSlice = createSlice({
  name: 'markers',
  initialState,
  reducers: {
    setMarkers(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const markersActions = markersSlice.actions;
export default markersSlice;

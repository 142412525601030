import { createSlice } from '@reduxjs/toolkit';
import { getLocalStorageData } from '../utils';

// Parse the userConfig from localStorage or use the default value
const initialState = getLocalStorageData('userConfig', {
  critical: [],
  fleet: { sort: { attribute: 'pta', mode: 'asc' }, searchTerm: '' },
  viewportOptions: { truckIconsSize: 'normal', logotypesAndAcronymes: false },
  theme: 'default',
});

const userConfigSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setData(state, action) {
      // Merge the new data with the existing state
      const newState = { ...state, ...action.payload };
      // Save the updated state to localStorage
      localStorage.setItem('userConfig', JSON.stringify(newState));
      return newState;
    },
    setCritical(state, action) {
      state.critical = action.payload;
      // Save the updated state to localStorage
      localStorage.setItem('userConfig', JSON.stringify(state));
    },
    setSortMode(state, action) {
      state.fleet.sort = action.payload;
      // Save the updated state to localStorage
      localStorage.setItem('userConfig', JSON.stringify(state));
    },
    setSearchTerm(state, action) {
      state.fleet.searchTerm = action.payload;
      // Save the updated state to localStorage
      localStorage.setItem('userConfig', JSON.stringify(state));
    },
    setViewportOptions(state, action) {
      state.viewportOptions = action.payload;
      // Save the updated state to localStorage
      localStorage.setItem('userConfig', JSON.stringify(state));
    },
    setTheme(state, action) {
      state.theme = action.payload;
      // Save the updated state to localStorage
      localStorage.setItem('userConfig', JSON.stringify(state));
    },
  },
});

export const userConfigActions = userConfigSlice.actions;
export default userConfigSlice;

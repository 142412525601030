import React, { useEffect, useState } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import PropTypes from 'prop-types';
import { env } from '../env';
import { AppSSEProvider } from '../context/SSEContext';

const AuthGuard = ({ component }) => {
  const [status, setStatus] = useState(false);
  const isAuthenticated = env.REACT_APP_TEST_MODE ? true : useIsAuthenticated();

  const checkToken = async () => {
    try {
      setStatus(isAuthenticated);
    } catch (error) {
      console.error('Something went wrong', error);
    }
  };

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  return status ? <AppSSEProvider>{component}</AppSSEProvider> : <></>;
};

AuthGuard.propTypes = {
  component: PropTypes.element.isRequired,
};

export default AuthGuard;

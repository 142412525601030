import { createTheme } from '@mui/material';

export const getDesignTokens = mode => {
  const modeTokens = {
    default: {
      palette: { ...DEFAULT_PALETTE },
      typography: { ...DEFAULT_TYPOGRAPHY },
      components: { ...DEFAULT_COMPONENTS },
    },
    'color-blind': {
      palette: { ...COLOR_BLIND_PALETTE },
      typography: { ...COLOR_BLIND_TYPOGRAPHY },
      components: { ...COLOR_BLIND_COMPONENTS },
    },
  };

  return createTheme({
    ...modeTokens[mode],
  });
};

const PALETTE = {
  truck: {
    main: '#4f575e', // neutral_8
    possible: '#d08700', // yellow_5
    optimal: '#74b816', // lime_4
    current: '#1a79cb', // blue_4
  },
  tertiary: {
    main: '#4F575E',
    tertiary_1: '#B6B63A',
  },
  success: {
    main: '#008C49',
  },
  neutral: {
    neutral_1: '#f8f9fa',
    neutral_2: '#f1f3f5',
    neutral_3: '#e9ecef',
    neutral_4: '#dee2e6',
    neutral_5: '#ced4da',
    neutral_6: '#adb5bd',
    neutral_7: '#6a7178',
    neutral_8: '#4f575e',
    neutral_9: '#272b30',
    neutral_10: '#101213',
    white: '#FFF',
  },
  red: {
    red_1: '#faeaea',
    red_2: '#e9aaaa',
    red_3: '#d96a6a',
    red_4: '#c92a2a',
    red_5: '#ab2424',
    red_6: '#8c1d1d',
    red_7: '#6e1717',
  },
  orange: {
    orange_1: '#fef0e6',
    orange_2: '#fcc29c',
    orange_3: '#f99551',
    orange_4: '#f76707',
    orange_5: '#d25705',
    orange_6: '#ad4805',
    orange_7: '#883904',
  },
  yellow: {
    yellow_1: '#fef5e5',
    yellow_2: '#fbd999',
    yellow_3: '#f8bc4c',
    yellow_4: '#f59f00',
    yellow_5: '#d08700',
    yellow_6: '#ac6f00',
    yellow_7: '#875700',
  },
  lime: {
    lime_1: '#f8f8eb',
    lime_2: '#c7e3a2',
    lime_3: '#9ecd5c',
    lime_4: '#74b816',
    lime_5: '#639c13',
    lime_6: '#51810f',
    lime_7: '#40650c',
  },
  green: {
    green_1: '#ebf7ed',
    green_2: '#afe0b8',
    green_3: '#73c982',
    green_4: '#37b24d',
    green_5: '#2f9741',
    green_6: '#267d36',
    green_7: '#1e622a',
    green_8: '#008c49',
  },
  teal: {
    teal_1: '#e6f2ef',
    teal_2: '#9cccbd',
    teal_3: '#52a58c',
    teal_4: '#087f5b',
    teal_5: '#076c4d',
    teal_6: '#065940',
    teal_7: '#044632',
  },
  cyan: {
    cyan_1: '#e7f2f4',
    cyan_2: '#9eccd3',
    cyan_3: '#56a6b2',
    cyan_4: '#0d8091',
    cyan_5: '#0b6c7b',
    cyan_6: '#095a66',
    cyan_7: '#074650',
  },
  blue: {
    blue_1: '#e5f0fe',
    blue_2: '#a3c9ea',
    blue_3: '#5fa1db',
    blue_4: '#1a79cb',
    blue_5: '#1667ad',
    blue_6: '#12558e',
    blue_7: '#0e4370',
  },
  indigo: {
    indigo_1: '#eceffd',
    indigo_2: '#b3c1f7',
    indigo_3: '#7b92f1',
    indigo_4: '#4263eb',
    indigo_5: '#3854c8',
    indigo_6: '#2e45a5',
    indigo_7: '#243681',
  },
  violet: {
    violet_1: '#f1edfd',
    violet_2: '#c6b6f6',
    violet_3: '#9b7fef',
    violet_4: '#7048e8',
    violet_5: '#5f3dc5',
    violet_6: '#4e32a2',
    violet_7: '#3e2880',
  },
  grape: {
    grape_1: '#f7ecfa',
    grape_2: '#dfb2e9',
    grape_3: '#c678d9',
    grape_4: '#ae3ec9',
    grape_5: '#9435ab',
    grape_6: '#7a2b8d',
    grape_7: '#60226f',
  },
  pink: {
    pink_1: '#fbebf0',
    pink_2: '#efadc4',
    pink_3: '#e27098',
    pink_4: '#d6336c',
    pink_5: '#b62b5b',
    pink_6: '#96244c',
    pink_7: '#761c3b',
  },
};

const TYPOGRAPHY = {
  fontSize: 14,
  fontFamily: "'Manrope', 'Helvetica', 'Arial', sans-serif",
  lineHeight: 21,
};

const COMPONENTS = {};

const DEFAULT_PALETTE = {
  ...PALETTE,
  primary: {
    main: '#003f33', // teal_green
  },
  secondary: {
    main: '#5f8a8b', // steel_teal
  },
  action: {
    active: '#5f8a8b', // steel_teal
    hover: '#edf2f2', // platinum
  },
  text: {
    primary: '#003f33',
    contrast: '#fff',
  },
  background: {
    default: '#fff',
    alt: '#003F33',
    contrast: '#003f33',
  },
};

const DEFAULT_TYPOGRAPHY = {
  ...TYPOGRAPHY,
  body1: {
    color: '#000000',
  },
  body2: {
    color: DEFAULT_PALETTE.neutral.neutral_8,
  },
  captionValue: {
    color: '#ffffff',
    fontWeight: 800,
    fontSize: '14px',
  },
};

const DEFAULT_COMPONENTS = {
  MuiPaper: {
    defaultProps: {
      sx: {
        backgroundColor: '#fff',
      },
    },
  },
  MuiButton: {
    defaultProps: {
      size: 'medium',
    },
  },
  MuiCheckbox: {
    defaultProps: {
      size: 'medium',
    },
  },
  MuiFormControlLabel: {
    defaultProps: {
      size: 'medium',
    },
  },
  MuiIconButton: {
    defaultProps: {
      size: 'medium',
    },
  },
  MuiInputBase: {
    defaultProps: {
      size: 'medium',
    },
  },
  MuiSelect: {
    defaultProps: {
      size: 'medium',
    },
  },
  MuiToggleButton: {
    defaultProps: {
      size: 'medium',
    },
  },
  MuiToggleButtonGroup: {
    defaultProps: {
      size: 'medium',
    },
  },
};

const COLOR_BLIND_PALETTE = {
  ...PALETTE,
  primary: {
    main: '#fefd32',
  },
  secondary: {
    main: '#fefd32',
  },
  action: {
    active: '#4f575e',
    hover: '#4f575e',
  },
  success: {
    main: '#fefd32',
  },
  text: {
    primary: '#fefd32',
    contrast: '#000',
  },
  background: {
    alt: '#000',
    contrast: '#fefd32',
    paper: '#000',
    default: '#000',
  },
};

const COLOR_BLIND_TYPOGRAPHY = {
  body1: {
    color: COLOR_BLIND_PALETTE.primary.main,
  },
  body2: {
    color: COLOR_BLIND_PALETTE.primary.main,
  },
  captionValue: {
    color: COLOR_BLIND_PALETTE.primary.main,
    fontWeight: 800,
    fontSize: '14px',
  },
};

const COLOR_BLIND_COMPONENTS = {
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: '.875rem',
      },
    },
  },
  MuiPaper: {
    defaultProps: {
      sx: {
        backgroundColor: '#000',
      },
    },
  },
  MuiButton: {
    defaultProps: {
      // sx: {
      //   color: 'inherit !important',
      //   background: DEFAULT_PALETTE.neutral.neutral_11,
      //   '&:hover' : {
      //     color: `${DEFAULT_PALETTE.neutral.neutral_11} !important`,
      //   },
      // },
      size: 'medium',
    },
  },
  MuiCheckbox: {
    defaultProps: {
      sx: {
        color: 'inherit',
      },
      size: 'medium',
    },
  },
  MuiFormControlLabel: {
    defaultProps: {
      size: 'medium',
    },
  },
  MuiIconButton: {
    defaultProps: {
      size: 'medium',
    },
  },
  MuiInputBase: {
    defaultProps: {
      size: 'medium',
    },
  },
  MuiSelect: {
    defaultProps: {
      size: 'medium',
    },
  },
  MuiToggleButton: {
    defaultProps: {
      size: 'medium',
    },
  },
  MuiToggleButtonGroup: {
    defaultProps: {
      size: 'medium',
    },
  },
};

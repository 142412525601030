import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';

const Loading = ({ type }) => {
  if (type === 'route')
    return (
      <div className="route-loading">
        <LinearProgress />
      </div>
    );
  return <>{open && <div className="cmp-loading">Loading ...</div>}</>;
};

Loading.propTypes = {
  type: PropTypes.string,
};

export default Loading;

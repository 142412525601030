import { createSlice } from '@reduxjs/toolkit';
const searchParams = new URLSearchParams(window.location.search);

const initialState = Object.freeze({
  layers: null,
  view: null,
  id: null,
  fid: null,
  lid: null,
  eid: null,
  ecl: null,
  ht: null,
  filter: null,
  menu: null,
  leftSidebar: false,
  rightSidebar: false,
  viewport: searchParams.get('viewport') ?? null,
});

const paramsSlice = createSlice({
  name: 'params',
  initialState,
  reducers: {
    setData(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setMenu(state, action) {
      return {
        ...state,
        menu: action.payload,
      };
    },
    setLeftSidebar(state, action) {
      return {
        ...state,
        leftSidebar: action.payload,
      };
    },
    setRightSidebar(state, action) {
      return {
        ...state,
        rightSidebar: action.payload,
      };
    },
    setViewport(state, action) {
      return {
        ...state,
        viewport: action.payload,
      };
    },
  },
});

export const paramsActions = paramsSlice.actions;
export default paramsSlice;

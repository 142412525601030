import React, { lazy, Suspense } from 'react';
import { Route, Navigate } from 'react-router-dom';
import AuthGuard from './guards/AuthGuard';
import { store } from './store/store';
import { Provider } from 'react-redux';
import Layout from './components/Layout';
import { getEnv } from './utils';
import Loading from './components/UI/Loading';

const HomePage = lazy(() => import('./pages/HomePage'));
const SwaggerPage = lazy(() => import('./pages/SwaggerPage'));
const UsersPage = lazy(() => import('./pages/UsersPage'));
const TrackAndTracePage = lazy(() => import('./pages/TrackAndTracePage'));
const SimpleFleetPage = lazy(() => import('./pages/SimpleFleetPage'));
const StyleguidePage = lazy(() => import('./pages/StyleguidePage'));

const routes = [
  <Route
    key="HomePage"
    path="/"
    element={
      <AuthGuard
        component={
          <Provider store={store}>
            <Layout className="home">
              <Suspense fallback={<Loading type="route" />}>
                <HomePage />
              </Suspense>
            </Layout>
          </Provider>
        }
      />
    }
  />,
  <Route
    key="SimpleFleet"
    path="/simple-fleet"
    element={
      <AuthGuard
        component={
          <Provider store={store}>
            <Layout className="simple-fleet">
              <Suspense fallback={<Loading type="route" />}>
                <SimpleFleetPage />
              </Suspense>
            </Layout>
          </Provider>
        }
      />
    }
  />,
  <Route
    key="TrackAndTracePage"
    path="/track-and-trace"
    element={
      <AuthGuard
        component={
          <Provider store={store}>
            <Layout className="track-and-trace">
              <Suspense fallback={<Loading type="route" />}>
                <TrackAndTracePage />
              </Suspense>
            </Layout>
          </Provider>
        }
      ></AuthGuard>
    }
  />,
  <Route key="Default" path="*" element={<Navigate to="/" />} />,
];

if (['development', 'testing'].includes(getEnv())) {
  routes.push(
    <Route
      key="SwaggerPage"
      path="/docs"
      element={
        <AuthGuard
          component={
            <Provider store={store}>
              <Layout className="docs">
                <Suspense fallback={<Loading type="route" />}>
                  <SwaggerPage />
                </Suspense>
              </Layout>
            </Provider>
          }
        ></AuthGuard>
      }
    />
  );
}

if (getEnv() === 'development') {
  routes.push(
    <Route
      key="UsersPage"
      path="/users"
      element={
        <AuthGuard
          component={
            <Provider store={store}>
              <Layout className="users">
                <Suspense fallback={<Loading type="route" />}>
                  <UsersPage />
                </Suspense>
              </Layout>
            </Provider>
          }
        />
      }
    />
  );
  routes.push(
    <Route
      key="StyleguidePage"
      path="/styleguide"
      element={
        <AuthGuard
          component={
            <Provider store={store}>
              <Layout className="styleguide">
                <Suspense fallback={<Loading type="route" />}>
                  <StyleguidePage />
                </Suspense>
              </Layout>
            </Provider>
          }
        ></AuthGuard>
      }
    />
  );
}

export default routes;
